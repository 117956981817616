.cardContainer {
  border: 2px solid #FFFFFF;
  border-radius: 10px;
  min-height: 40rem;
  width: 100%;
  padding-top: 2rem;
}

@media screen and (min-width: 1580px) {
  .cardContainer { 
    margin: auto 13% !important;
    width: 86%;
    padding: 14rem 3rem !important;
  }
}

@media screen and (max-width: 760px) {
  .cardContainer {
    background-size: cover !important;
    background-position: center !important;
  }
}

@media screen and (max-width: 481px) {
  .cardContainer {
    width: 90% !important;
    background-position: 68% 50% !important;
    padding: 2rem 1rem !important;
    background-size: 1056px 516px !important;
    min-height: 37rem;
  }
}

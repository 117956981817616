@import 'styles/constants.css';

.contentDescriptionContainer {
    display: flex;
    flex-direction: column;
    max-width: 33rem;

    .contentTitle {
        line-height: 1;
        font-size: 3em;
        text-transform: uppercase;
        color: white;
        letter-spacing: 0;
        text-align: left;
    }
    
    .contentDescription {
        color: var(--text-light-primary);
        margin-top: 0.5rem; 
        margin-bottom: 2rem;
        line-height: 1.75;
        font-size: 1.5em;
        text-align: left;
    }

    .captionText {
        color: white;
        width: 70%;
        font-size: 11px;
        letter-spacing: 0.04em;
        margin-top: 2rem;
        font-weight: 500;
        line-height: 175%;
        text-align: left;
    }
}

@media screen and (max-width: 1200px) {
    .captionText {
        width: 80% !important;
    }
}

@media screen and (max-width: 960px) {
    .contentDescriptionContainer {
        width: 100% !important;
        padding: 0 1.5rem;
    }
}

@media screen and (max-width: 429px) {
    .contentDescriptionContainer{
        padding: 0;
    }

    .contentTitle {
        font-size: 26px !important;
        text-align: center;
    }
    
    .contentDescription {
        text-align: center;
    }

    .buttonContainer {
        display: flex;
        justify-content: center;
    }

    .captionText {
        width: 100% !important;
        text-align: center;
    }
}
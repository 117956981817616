@import "styles/constants.css";

header {
  position: fixed;
  width: 100%;
  top: 0;
  background: transparent;
  z-index: 2;
}

.navbar-background {
  height: var(--navbar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 64px;
  background: var(--bg-dark-primary);
}

.navbar-transparent {
  height: var(--navbar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 64px;
  background: transparent;
}

.navbar-menu {
  display: flex;
  justify-content: space-between;
  right: 0;
  align-items: center;
  gap: 35px;
}

li {
  list-style: none;
}

.navbar-link {
  color: #fff;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
}

.social-media-img {
  height: 25px;
  margin-bottom: -6px;
}

.navbar-link:hover {
  cursor: pointer;
}

.link-stake {
  color: var(--pink-primary);
  padding: 12px 35px;
}

.item-stake {
  border: 1px solid var(--pink-primary);
  border-radius: 10px;
  padding: 10px 0px;
}

.navbar-punks-logo {
  max-width: 100%;
  height: 58px;
}

.navbar-punks-logo:hover {
  cursor: pointer;
}

.navbar-link:hover {
  color: #d93978;
}

.item-stake:hover {
  background: #d93978;
}

.link-stake:hover {
  color: #fff;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  background-color: rgb(24, 254, 23);
}

.bar:nth-child(2) {
  width: 115%;
}

@media (max-width: 875px) {
  .hamburger {
    display: block;
  }

  .navbar > a {
    z-index: 1;
  }

  .navbar-menu {
    position: fixed;
    top: 0px;
    left: -100%;
    flex-direction: column;
    background-color: #0c1121;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .navbar-link {
    font-size: 28px;
  }

  .navbar-link > img {
    height: 35px;
  }

  .link-stake {
    color: #fff;
  }

  .item-stake {
    border: none;
  }

  .item-stake:hover {
    background: none;
  }

  .link-stake:hover {
    color: #d93978;
  }

  .navbar-menu.active {
    left: 0;
  }
}

@media (max-width: 425px) {
  .navbar-link {
    font-size: 18px;
  }
}

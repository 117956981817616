.checkboxContainer {
  background-color: #18223E;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 7rem !important;

  .checkboxLabel {
    width: 70%;
    color: white;
    margin-right: 6px;
    text-align: left;
  }
}

@media screen and (max-width: 481px) {
  .checkboxContainer {
    padding: 0px 7px;
  }
}
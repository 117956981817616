@media screen and (min-width: 1580px) {
  .boxContainer { 
    max-width: 450px;
  }
}

@media screen and (max-width: 760px) {
  .boxContainer { 
    max-width: 450px;
  }
}
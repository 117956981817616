footer {
  margin-top: 5rem;
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-punks-logo {
  max-width: 100%;
  height: 120px;
  margin-bottom: 12px;
}

.footer-title {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: #d93978;
  margin-bottom: 15px;
}

.social-links {
  margin-bottom: 45px;
}

.social-icons {
  height: 50px;
}

.contacts-container,
.links-container,
.legal-docs,
.footer-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 200%;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #fff;
}

.links-container {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.links-container > * {
  padding: 0px 30px;
}

.footer-links {
  color: #fff;
  text-decoration: none;
}

.footer-links:hover {
  cursor: pointer;
}

.footer-pv-logo {
  margin-top: 45px;
  margin-bottom: 20px;
  max-width: 100%;
  height: 50px;
}

.footer-legal-container * {
  font-size: 0.7rem !important;
  font-weight: 600;
}

.legal-docs > *,
.contacts-container > * {
  padding: 2px 5px;
}

.contacts-container {
  margin-bottom: 20px;
}

footer a:hover {
  color: #d93978;
}

.alt-footer {
  display: flex;
  flex-direction: column;
  max-width: 1700px;
  padding: 2rem 4% 2rem 4%;
  margin: auto;
}

.horz-line {
  border: 1px solid rgba(199, 196, 231, 0.3);
}

.alt-footer-icons,
.alt-footer-links {
  display: flex;
  flex-direction: row;
}

.alt-footer-icons {
  align-items: center;
  justify-content: center;
  gap: 100px;
  margin-bottom: 100px;
  margin-top: 10rem;
}

.alt-footer-icons div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.alt-footer-icons div a {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: var(--text-light-primary);
  transition: all 0.3s ease;
}

.alt-icon-img {
  height: 42px;
}

.alt-footer-links {
  justify-content: space-between;
  margin-bottom: 1em;
}

.alt-footer-links span {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  color: rgba(255, 255, 255, 0.4);
  margin-top: 1.8em;
}

.alt-footer-links div {
  display: flex;
  gap: 28px;
  margin-top: 1.8em;
}

.footer-shop-links {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 175%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--text-light-primary);
  transition: all 0.3s ease;
}

.footer-shop-links:hover,
.alt-footer-legals:hover,
.alt-footer-icons a:hover {
  color: #8c5bff;
}

.alt-footer-legals {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 175%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.4);
}

@media (max-width: 635px) {
  .links-container,
  .legal-docs,
  .contacts-container,
  .alt-footer-icons,
  .alt-footer-links {
    flex-direction: column;
  }

  .alt-footer-links {
    align-items: center;
  }
}

.phase-section {
    width: auto !important;
    grid-column-gap: 0;
    column-gap: 0;
}

.phase-section .column-container {
    width: 85rem;
    z-index: 1;
    margin: 0px 0px;
}

.minting .column-container {
    margin: 150px 75px 0px 75px;
}

.phase-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.pool {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
}

.pool-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.pool-grid img {
    height: 90px;
}

.flex-row {
    display: flex;
    flex-direction: row;
    background-color: #18223e;
    align-items: center;
    border-radius: 10px;
    padding: 15px;
    gap: 22px;
    /* flex: 1; */
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.pool-title {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 125%;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: #fff;
}

.pool-claimed {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 175%;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: #ff3c88;
}

.phases {
    margin: 100px 0px 100px 0px;
}

.content-title {
    font-style: normal;
    font-weight: 800;
    font-size: calc(30px + (36 - 30) * (100vw - 950px) / (1440 - 950));
    line-height: 125%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 35px;
}

.phase-container {
    display: flex;
    flex-direction: column;
    padding: 28px;
    gap: 19px;
    background: #18223e;
    border-radius: 10px;
}

.phase-title {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 145%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
}

.phase-text {
    font-style: normal;
    font-weight: 800;
    font-size: 11px;
    line-height: 145%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
}

.phase-items {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 175%;
    letter-spacing: 0.04em;
    display: flex;
    justify-content: space-between;
}

.phase-items span:first-child {
    margin-right: 5px;
}

.phase-items span:last-child {
    white-space: nowrap;
}

.phase-duration {
    font-weight: 800;
    font-size: 11px;
    line-height: 145%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
}

.countdown-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
}

.countdown-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.phase-countdown {
    font-style: normal;
    font-weight: 800;
    font-size: 11px;
    line-height: 145%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
}

@media (max-width: 1440px) {
    .phases {
        margin: 100px 75px 50px 75px;
    }
    .phase-content {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1440px) {
    .content-title {
        font-size: 36px;
    }
}

@media (max-width: 1150px) {
    .pool-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 1080px) {
    .pool-title {
        font-size: 12px;
    }
    .pool-claimed {
        font-size: 10px;
    }
}

@media (max-width: 705px) {
    .minting .column-container {
        margin: 0px 75px;
    }
    .phase-content {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 600px) {
    .pool-grid .flex-row {
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }
    .flex-row {
        gap: 10px;
    }
    .phase-section {
        margin: 0px 8%;
    }

    .phase-section .column-container {
        width: 100%;
    }

    .minting .column-container {
        width: 100%;
        margin: 0px 0px;
    }
    .phases {
        margin: 50px 0px;
    }
}

@media (max-width: 425px) {
    .phase-section .column-container {
        margin: 0;
    }
    .minting .column-container {
        margin-top: 50px;
    }
    .flex-row img {
        height: 70px;
    }
}

.split-pane {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
    grid-column-gap: 5rem;
    column-gap: 5rem;
}

.split-pane > img {
    width: 60% !important;
}

@media screen and (max-width: 1800px) {
    .content-description-container {
        width: 93%;
    }
}

@media (max-width: 1280px) {
    .split-pane {
        width: 97vw !important;
    }
}
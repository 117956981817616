.opencomicImage {
  max-width: 539px !important;
}

.originStoriesHeader {
  margin-top: 5rem !important;
  margin-bottom: -5rem;
  text-align: center;
  color: white;
}

@media screen and (min-width: 1240px) {
  .opencomicImage {
    margin-left: 5rem;
  }
}
@media screen and (min-width: 1775px) {
  .opencomicImage {
    max-width: 20vw;
    margin-left: 9rem;
  }
}

@media screen and (max-width: 768px) {
  .opencomicImage {
    max-width: 350px !important;
  }
}

@media screen and (max-width: 400px) {
  .opencomicImage {
    max-width: 290px !important;
  }
}
.counter-container {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border-radius: 7px;
    width: 109px;
    height: 56px;
    align-items: center;
    justify-content: center;
}

.counter-btn {
    border: none;
    padding: 0;
    background: none;
    width: 20%;
}

.amount-count {
    width: 45%;
}

.counter-btn,
.amount-count {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 175%;
    text-align: center;
    text-transform: uppercase;
    color: white;
}

.text-container {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 175%;
    color: #eb1f77;
}

@import 'styles/base.css';

.originStoriesPage {
  width: 100%;
  
  .os3IntroBackground {
    background: linear-gradient(0deg, #070A14 0%, rgba(7, 10, 20, 0) 15.95%), linear-gradient(180deg, rgba(12, 17, 33, 0.72) 0%, rgba(12, 17, 33, 0) 45.18%), linear-gradient(0deg, rgba(12, 17, 33, 0.6), rgba(12, 17, 33, 0.6)), radial-gradient(46.96% 49.66% at 64.36% 47.26%, rgba(12, 17, 33, 0.55) 31.15%, rgba(12, 17, 33, 0) 100%), url('../../images/os3Background1.webp');
  }

  .os4IntroBackground {
    background: linear-gradient(0deg, #070A14 0%, rgba(7, 10, 20, 0) 15.95%), linear-gradient(180deg, rgba(12, 17, 33, 0.72) 0%, rgba(12, 17, 33, 0) 45.18%), linear-gradient(0deg, rgba(12, 17, 33, 0.6), rgba(12, 17, 33, 0.6)), radial-gradient(46.96% 49.66% at 64.36% 47.26%, rgba(12, 17, 33, 0.55) 31.15%, rgba(12, 17, 33, 0) 100%), url('../../images/os4Background1.webp');
  }

  .introSection {
    padding: 10% 12%;
    margin-top: -84px;
    background-repeat: no-repeat;
    background-size: cover;

    .introColumnleft {
      display: flex;
      justify-content: center;
      margin-left: -7rem;
      width: 50%;

      .os3NFTCoverImg {
        max-width: 400px;
        background: radial-gradient(85.58% 61.69% at -15.2% -18.85%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
        background-blend-mode: hard-light, normal;
        box-shadow: inset 10.2659px 0px 12.9674px -7.02403px rgba(255, 255, 255, 0.19);
        filter: drop-shadow(4.62017px 4.62017px 10.1644px rgba(0, 0, 0, 0.2));
        transform: rotate(-1deg);
        border-radius: 5px;
      }
    }

    .introColumnRight {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 40%;
      
      .introSubtitle {
        font-style: italic;
        font-family: 'Issue';
      }
  
      .introTitle {
        color: white;
      }

      .introCTAButtons {
        display: flex;
        flex-direction: column;
        gap: 20px;

        button {
          width: 100%;
        }
      }
    }
  }



  .cardSection {
    background: linear-gradient(180deg, rgba(16, 26, 50, 0) 46.68%, #101A32 100%), #070A14;

    .os3Logo {
      max-height: 150px;
    }
    
    .os3CardBackground {
      background: radial-gradient(645.21% 100% at 57.95% 100%, rgba(12, 17, 33, 0.83) 45.04%, rgba(12, 17, 33, 0) 100%), url("../../images/os3Background2.webp"), #0C1121;
    }

    .os4CardBackground {
      background: radial-gradient(645.21% 100% at 57.95% 100%, rgba(12, 17, 33, 0.83) 45.04%, rgba(12, 17, 33, 0) 100%), url("../../images/os4Background2.webp"), #0C1121;
    }
    
    .os3Card {
      display: flex;
      justify-content:flex-start;
      background-position: center;
      width: 96%;
      margin: auto 2%;
      padding: 5rem 3rem;
      
      .os3CardTextContainer {
        width: 30rem;
        
        .cardText {
          text-align: left;
          margin-left: 2rem;
        }
      }
    }
  }

  .burnToClaimSectionContainer {
    padding: 9rem 10%;
    background: #101A32;
  }
  
  .burnToClaimLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    margin-right: 5rem;

    .burnToClaimBox {
      width: 100%;
      padding: 2rem;

      .burnToClaimImagesContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;

        .os3ComicImageThumbnail {
          height: 11rem;
          transform: rotate(-1deg);
          filter: drop-shadow(4.62017px 4.62017px 10.1644px rgba(0, 0, 0, 0.2));
        }
    
        .rightArrowIcon {
          color:white;
          width: 15px;
          margin: 0 2rem;
        }
    
        .os3NFTComicCoverThumbnail {
          height: 11rem;
          transform: rotate(1deg);
          box-shadow: inset 10.2659px 0px 12.9674px -7.02403px rgba(255, 255, 255, 0.19);
          filter: drop-shadow(4.62017px 4.62017px 10.1644px rgba(0, 0, 0, 0.2));
          border-radius: 1px;
        }
      }

      .burnToClaimTitle {
        line-height: 42.42px;
        font-size: 28px;
        color:white !important;
        text-align: center;
      }
    }
    
    
    .burnToClaimDescriptionText {
      text-align: center;
    }
  
    .btnContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;
      justify-content: center;
      margin-top: 0 !important;
    }

    .captionText {
      margin-top: 4rem;
      text-align: center;
    }
  }

  .burnToClaimRight {
    width: 70%;

    .burnText {
      text-align: left;
    }
  }

  .cardImageGalleryContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

}

/** Modals */

/** connect modal **/
.connectBtnContainer {
  padding: 2rem 0rem;
  display: flex;
  justify-content: center;
  
  .connectWalletBtn {
    background-color: var(--green-primary) !important;
    width: 100%;
  }
}

/** ready to burn modal **/
.scrollCheckboxPanel {
  height:250px;
  overflow-y: scroll;

  .smallCheckboxContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 14px;
    margin-bottom: 2rem;

    .smallCheckbox {
      width: 100%;
    }
  }
}

.maxReachedContainer {
  height: 2rem;
  margin-top: 2rem;
  margin-bottom: -3rem;

  .maxReachedText {
    text-align: center;
    color: var(--pink-primary);
    font-size: 16px;
    font-style: italic;
  }
}

.justifyCenter {
  justify-content: center;
}

.reviewTileText {
    width: 100%;
    color: white;
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;
}

.reviewTile {
  height: 2.5rem;
}

.burnFromContainer {
  position: relative;
  
  .fireLeft {
    position: absolute;
    left:-23px;
    bottom: 40px;
    transform: rotate(-3deg);
  }
  
  .fireRight {
    position: absolute;
    bottom: 0px;
    right: -23px;
    transform: rotate(8deg);
  }
}

.btnContainer {
  margin-top: 42px !important;
  margin-bottom: -30px !important;
}

.modalPinkButton {
  height: 44px !important;
  border-radius: 14px !important;
  margin-top: 10px !important;
  border: 1px solid !important;
  width: 100%
}

.collectionIcons {
  display: flex;
}



@media screen and (min-width: 1750px) {
  .burnToClaimRight {
    .burnText {
      max-width: 60rem;
    }
  }
  .introSection {
    padding: 10% 15% !important;

    p {
      max-width: 35rem;
    }
  }
   
}

@media screen and (min-width: 1880px) {
  .introSection {
    padding: 10% 23% !important;

    img.os3NFTCoverImg {
      max-width: 495px !important;
      margin-right: 11rem
    }
  }
}

@media screen and (min-width: 2100px) {
  .burnToClaimLeft {
    margin-right: 0rem !important;
  }
}

@media screen and (max-width: 1280px) {
  .introSection {
    padding: 10% 7% !important;
  }

  .burnToClaimSectionContainer {
    padding: 9rem 7% !important;
  }
  
  .introColumnleft {
    img.os3NFTCoverImg {
      max-width: 90% !important;
    }
  }
}

@media screen and (max-width: 960px) {
  .introSection {
    padding: 14% 0% !important;

    .introColumnleft {
      img.os3NFTCoverImg {
        max-width: 80% !important;
        
      }
    }
  }
}

// burn section - tablet view
@media screen and (max-width: 960px) and (min-width: 760px) {
  .burnToClaimSectionContainer {
    flex-direction: column-reverse;
    padding:0rem !important;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5rem 2rem !important;
  }
  
  .burnToClaimRight {
    width: 90% !important;
  
    .progressBarContainer {
      // display: block !important;
      width: 80% !important;
    }
  }
  
  .burnToClaimLeft {
    margin-top: 4rem;
    width: 60% !important;
    margin-right: 0rem !important;
  }
}

@media screen and (max-width: 760px) {
  .introSection {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 5% 0% !important;
    
    .introColumnleft {
      img.os3NFTCoverImg {
        margin-top: 6rem;
        margin-left: 6rem;
        max-width: 40%;
        align-items: center;
      }
    }
    
    .introColumnRight {
      width: 100% !important;
    }
  }

  .burnToClaimSectionContainer {
    flex-direction: column-reverse;
    padding:0rem !important;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 5rem 2rem !important;
  }

  .burnToClaimRight {
    width: 32rem !important;

    .progressBarContainer {
      // display: block !important;
      width: 80% !important;
    }
  }

  .burnToClaimLeft {
    margin-top: 2rem;
    width: 100% !important;
    margin-right: 0rem !important;

    .burnToClaimImagesContainer {
      margin-left: 2rem;
    }
  }
}

@media screen and (max-width: 750px) {
  .introSection {
    padding-bottom: 4rem !important;
  }

  .introColumnRight p {
    margin: 2rem 3%;
  }

  .burnToClaimBox {
    width: 100% !important;
    max-width: 32rem;
  }
}

@media screen and (max-width: 481px) {
  .introSection {
    .os3IntroBackground {
      background: linear-gradient(0deg, #070A14 0%, rgba(7, 10, 20, 0) 15.95%), linear-gradient(180deg, rgba(12, 17, 33, 0.72) 0%, rgba(12, 17, 33, 0) 45.18%), linear-gradient(0deg, rgba(12, 17, 33, 0.6), rgba(12, 17, 33, 0.6)), radial-gradient(46.96% 49.66% at 64.36% 47.26%, rgba(12, 17, 33, 0.55) 31.15%, rgba(12, 17, 33, 0) 100%), url('../../images/os4Background1Mobile.webp');
    }

    .introColumnleft {
      img.os3NFTCoverImg {
        min-width: 250px !important;
      }
    }

    .introColumnRight {
      width: 90% !important;
      
      button {
        padding: 6px 4px !important;
      }
    }
   
  }
  
  .os3Card {
    margin-top: 2rem !important;
    
    .os3CardTextContainer{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      
      img {
        height: 100px;
      }

      p {
        font-size: 14px !important;
        line-height: 175%;
      }
    }
    
  }

  .burnToClaimSectionContainer {
    padding: 5rem 0rem !important;

    .burnToClaimRight {
      width: 90% !important;
    }

    .burnToClaimLeft {
      .burnToClaimBox {
        width: 95% !important;

        .burnToClaimImagesContainer {
          margin-left: 0rem !important;
        }

        .burnToClaimTitle {
          font-size: 26px;
        }
      }
    }
  }
}

.ape-town-page {
    width: 100%;
}

main {
    padding-top: var(--navbar-height);
    width: 100%;
}

.ape-banner {
    width: 100%;
    height: 85vh;
    object-fit: cover;
    background: url("../../images/ApeBanner1.webp");
    background-repeat: no-repeat;
    background-size: cover;
}

.divider-button {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1.5rem;
    text-align: center;
    align-items: center;
    background-color: var(--pink-primary);
    margin-top: -7px;
    border: none !important;
    color: var(--text-dark-primary);
    text-transform: uppercase;
    font-weight: 800;
    font-size: 18px;
    line-height: 31.5px;
    letter-spacing: 0.15em;
    text-decoration: none;
}

.divider-button:hover {
    color: white;
}

.ape-out-on-the-town {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 5rem 0rem;
    align-items: center;
    background-image: url('images/ApeBackground.jpg');
    margin-top: -2px;
}

.ape-out-on-the-town h1 {
    text-align: center;
    margin-top: 4rem;
}

/** closed event start **/
.ape-out-on-the-town-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.horizontal {
    display: flex;
    flex-direction: row;
    padding: 5rem 10%;
}

/** closed event end **/

.ape-out-on-the-town-text {
    max-width: 609px;
    margin-bottom: 4rem !important;
    font-weight: 500;
    font-size: 18px !important;
    text-align: center;
}

.burn-to-claim-column {
    height: 90%;
}

.burn-to-claim-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.burn-to-claim-images-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: center;
}

.burn-to-claim-box {
    width: 31rem;
}

.connect-btn-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.counter {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.elite-ape-coin-img {
    height: 8rem;
    width: 10rem;
    margin-left: 1.5rem;
}

.ape-out-on-the-town-comic-img {
    height: 11rem;
}

.right-arrow-icon {
    color:white;
    width: 15px;
    margin: 0 2rem;
}

.burn-to-claim-text {
    text-align: center;
    align-items: center;
}

.burn-to-claim-text > p {
    margin-top: 1rem !important;
    margin-bottom: 0.5rem !important;
    width: 90%;
}

.burn-to-claim-close-date-info-text {
    color: var(--pink-primary);
    text-transform: uppercase;
    font-weight: 800;
    font-size: 17px;
    letter-spacing: 0.16em;
    margin-top: 2rem;
}

.burn-to-claim-btn {
    width: 100%;
}

.actions-btn-container {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.inner-section {
    padding-top: 10rem;
}

.opencomicImage {
    max-width: 600px !important;
}

.inner-section:nth-last-child(1) {
    padding-bottom: 10rem;
}

.progress-container {
    margin-top: 20px;
}

.ends-in {
    color: var(--pink-primary);
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    padding-top: 2rem;
}

@media screen and (max-width: 950px) {
    .ape-open-comic-img {
        margin-bottom: 3rem;
        width: 85vw;
    }
    .opencomicImage {
        width: 500px !important;
    }
}

@media screen and (max-width: 760px) {
    .ape-out-on-the-town-text {
        padding: 0 4% !important;
        max-width: 600px !important;
    }
}


@media screen and (max-width: 481px) {

    .burn-to-claim-box {
        width: 23rem;
    }

    .burn-to-claim-images-container{
        margin-left: -49px;
    }

    .burn-to-claim-close-date-info-text {
        font-size: 13px;
    }

    .description-container {
        padding: 1rem 4rem;
    }
}

@media screen and (max-width: 345px) {
    .divider-button {
        margin-top: -89px;
    }

    .elite-ape-coin-img {
        height:7rem;
    }

    .burn-to-claim-box {
        padding: 16px !important;
    }
    
    .burn-to-claim-images-container{
        margin-left: -40px;
    }
}

@media screen and (max-width: 329px) {
    .elite-ape-coin-img {
        height: 5rem;
        width: 7rem;
        margin-left: 1.5rem;
    }
    
    .ape-out-on-the-town-comic-img {
        height: 9rem;
    }
    .burn-to-claim-images-container{
        margin-left: -30px !important;
    }
}


@media screen and (max-width: 500px) {
    .ape-banner {
        background: url("../../images/mobile/ApeBannerMobile.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: -25% !important;
    }

    .divider-button {
        margin-top: -41px;
    }

    .ape-out-on-the-town {
        margin-top: -2px;
    }

    .burn-to-claim-box {
        width: 87vw;
    }

    .burn-to-claim-images-container{
        margin-left: -49px;
    }

    .burn-to-claim-close-date-info-text {
        font-size: 13px;
    }

    .description-container {
        padding: 1rem 4rem;
    }
}

/* @media screen and (min-width: 350px) and (max-width: 408px) {
    .ape-banner {
        margin-bottom: -60% !important;
    }
} */


@import "styles/base.css";
@import "styles/semantic-ui.css";
@import "semantic-ui-css/semantic.min.css";

@font-face {
  font-family: "PP Telegraf";
  src: url(../src/fonts/PPTelegraf-Bold.woff);
  font-weight: 700;
}

@font-face {
  font-family: "PP Telegraf";
  src: url(../src/fonts/PPTelegraf-Regular.woff);
  font-weight: 400;
}

@font-face {
  font-family: "Issue";
  src: url(../src/fonts/BackIssuesBB_reg.ttf);
  font-weight: 400;
}

.App {
  min-height: 100vh;
  background-color: #0c1121;
  max-width: 100%;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  background: black;
  font-family: "PP Telegraf";
  font-weight: 400;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

button {
  color: #000;
}
@import 'styles/constants.css';

.confirmationModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 418px;
  padding: 20px 3rem;
  text-align: center;
  word-wrap: break-word;
  color: white;
  border-radius: 10px !important;
  background-color: var(--ocean-blue-primary) !important;


  .closeButton {
    position: absolute;
    top: 20px;
    right: 10px;
    color: white !important;
    cursor: pointer;
    background: url('../../images/ClosePopupIcon.svg') no-repeat;
    border: none;
    width: 40px;
    height: 25px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
  }

  .container {
    text-align: center;
    width: 100% !important;
    background-color: var(--ocean-blue-primary) !important;
    padding-top: 1rem;

    .main {
      
      .title {
        font-size: 30px;
        font-weight: 800;
        line-height: 43.5px;
        text-align: center;
        text-transform: uppercase;
      }

      .titleSuccess {
        color: var(--green-primary);
      }

      .subTitle {
        color: #71819e;
        font-size: 16px;
        margin: 30px 0px !important;
      }

      .message {
        color: white;
        font-weight: 500;
        font-size: 16px;
        margin: 15px auto;
        line-height: 28px;
        letter-spacing: 0.04em !important;
      }
      
      .confirmButton {
        width: 100%;
        border-radius: 14px !important;
        border: 1px solid !important;
        padding: 0px !important;
        margin-top: 3.5rem;
        margin-bottom: 2rem;
        height: 3rem !important;

        &:disabled {
          background-color: #458746;
          opacity: 0.7;
          cursor:not-allowed;
        }
      }

      .caption {
        color: white;
        font-size: 11px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 1.5rem;
        line-height: 19.25px;
      }
    }
  }
}

@media screen and (max-width: 481px) {
  .confirmationModal {
    min-width: 349px;
    padding: 8px 12px;
  }
}
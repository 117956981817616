section {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* margin: 0 75px; */
    z-index: 1;
    width: 1290px;
}

.info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 40%;
}

.glitch-section .container .info-container,
.cores-section .container .info-container {
    margin-right: 9%;
}

.orb-section {
    padding: 4% 0;
}

.orb-section .container .info-container {
    margin-left: 6%;
    padding-top: 5%;
    max-width: 23em;
    margin-right: 15%;
}

.info-title {
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    font-size: calc(45px + (60 - 45) * (100vw - 950px) / (1440 - 950));
    line-height: 125%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #fff;
    margin-bottom: 28px;
}

.glitch-section .info-title {
    width: 70%;
}

.orb-section .info-title {
    width: 65%;
}

.orb-section .info-title {
    font-family: Issue;
    font-style: italic;
    font-weight: 700;
    font-size: 26px;
    line-height: 156%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #ff3c88;
    margin-bottom: 28px;
}

.info-text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 175%;
    letter-spacing: 0.04em;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #ffffff;
}

.glitch-section .image-container {
    box-shadow: inset 17px 0px 18px -11px rgb(255 255 255 / 58%);
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.3));
    transform: rotate(2.19deg);
    background: radial-gradient(86% 62% at -15% -19%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    width: 430px;
}

.glitch-img {
    width: 100%;
    mix-blend-mode: hard-light;
    object-fit: cover;
    margin-bottom: -0.3em;
}

.orb-section .image-container {
    position: relative;
    width: 50%;
    margin-left: 5%;
    max-width: 960px;
    min-width: 440px;
}

.orb-img {
    width: 100%;
    object-fit: contain;
    margin-bottom: -0.3em;
}

.cores-img {
    width: 625px;
    height: 625px;
}

.top-subtitle {
    font-family: Issue;
    font-style: italic;
    font-weight: 700;
    font-size: 16px;
    line-height: 175%;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #ff3c88;
}

.bottom-subtitle {
    color: #ff3c88;
    margin-top: 42px;
    font-family: Issue;
    font-style: italic;
    font-weight: 700;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-feature-settings: "pnum" on, "lnum" on;
    font-size: 16px;
    line-height: 215%;
}

.cores-section .bottom-subtitle {
    font-size: 20px;
    line-height: 175%;
}

.glitch-section {
    background: linear-gradient(1.83deg, #0c1121 0%, rgba(12, 17, 33, 0) 44%),
        linear-gradient(90deg, #0c1121 22%, rgba(12, 17, 33, 0) 62%),
        linear-gradient(180deg, rgba(12, 17, 33, 0.72) 0%, rgba(12, 17, 33, 0) 45%),
        linear-gradient(0deg, rgba(12, 17, 33, 0.74), rgba(12, 17, 33, 0.74)),
        radial-gradient(58% 62% at 27% 49%, rgba(12, 17, 33, 0.55) 31%, rgba(12, 17, 33, 0) 100%),
        url("../../images/desktop/punks3_landing_bg@2x.jpg");
    background-size: cover;
    height: calc(774px + 9vh);
    padding-top: 10vh;
    position: relative;
    background-position: center;
    max-height: 1000px;
}

.glitch-pixel {
    background: url("../../images/desktop/punks3_landing_pixels@2x.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    width: 2349px;
    height: 1097px;
    top: -17px;
}

.cores-section {
    background: linear-gradient(269.26deg, #0c1121 35%, rgba(12, 17, 33, 0) 100%),
        linear-gradient(180deg, #0c1121 0%, rgba(12, 17, 33, 0) 58%),
        linear-gradient(0deg, #0c1121 0%, rgba(12, 17, 33, 0) 42%),
        linear-gradient(0deg, rgba(12, 17, 33, 0.6), rgba(12, 17, 33, 0.6)),
        radial-gradient(58% 62% at 28% 49%, rgba(12, 17, 33, 0.55) 31%, rgba(12, 17, 33, 0) 100%),
        url("../../images/desktop/punks3_mint_bg@2x.jpg");
    background-size: cover;
    position: relative;
    background-position: center;
}

.cores-section .image-container {
    margin-right: -5%;
}

.cores-pixel {
    background: url("../../images/desktop/punks3_mint_pixels@2x.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -35px;
    width: 2554px;
    height: 960px;
}

.cores-section .info-title {
    font-size: calc(30px + (36 - 30) * (100vw - 950px) / (1440 - 950));
}

.orb-section .info-container {
    order: 1;
}

.orb-section .img-container {
    order: 2;
}

.info-container .btn {
    width: auto;
    height: 46px;
    font-size: 16px;
    margin-top: 52px;
    background-color: #14c918;
}

.animation-gif {
    height: 100%;
    width: 627px;
}
.punks3 {
    margin: 50px 75px 100px 75px;
}
.punks3-section {
    text-align: center;
}

.punks3-row {
    display: flex;
    flex-direction: row;
    gap: 6vw;
}

.punks3-column img {
    height: 403px;
    width: 262px;
}

.punks3-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.punks3-column:first-child img {
    transform: rotate(2.19deg);
}
.punks3-edition-title span {
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.16em;
}
.punks3-edition-title {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #ffffff;
}

@media (min-width: 1440px) {
    .info-title {
        font-size: 60px;
    }
    .cores-section .info-title {
        font-size: 36px;
    }
}
@media (max-width: 1440px) {
    .container {
        width: 100%;
    }
    .cores-section .container {
        padding: 0 75px;
    }
}

@media (max-width: 1200px) {
    .info-container {
        width: 50%;
        max-width: 24em;
    }

    .orb-section .container .info-container {
        margin-right: 5%;
        margin-left: 2%;
    }

    .orb-section .image-container {
        margin-left: 0;
    }

    .cores-section .info-container {
        min-width: 23em;
    }

    .cores-section .container {
        justify-content: flex-start;
    }
}

@media (max-width: 950px) {
    .animation-gif {
        width: 457px;
    }
    .container {
        flex-direction: column;
        gap: 50px;
        padding: 0 75px;
    }

    .cores-section,
    .glitch-section {
        height: auto;
        max-height: none;
    }

    .glitch-section .container .info-container,
    .cores-section .container .info-container {
        margin-right: 0px;
    }

    .info-container {
        order: 2 !important;
        width: 100%;
        max-width: none;
    }

    .glitch-section .image-container {
        order: 1 !important;
        width: 240px;
    }

    .orb-section .container .info-container {
        padding-top: 0;
        margin: 0;
        width: 100%;
        max-width: none;
    }

    .cores-section .image-container {
        margin-right: 0;
    }

    .punks3 {
        margin: 50px 0px 100px 0px;
    }
    .punks3-row {
        flex-direction: column;
        gap: 50px;
        justify-content: center;
        align-items: center;
    }
    .punks3-edition-title {
        margin-top: 10px;
    }
}

@media (max-width: 708px) {
    .animation-gif {
        width: 350px;
    }
    .glitch-section {
        background: linear-gradient(0deg, #0c1121 60%, rgba(12, 17, 33, 0) 72%),
            linear-gradient(90deg, #0c1121 22%, rgba(12, 17, 33, 0) 62%),
            linear-gradient(180deg, rgba(12, 17, 33, 0.72) 0%, rgba(12, 17, 33, 0) 45%),
            linear-gradient(1deg, rgba(12, 17, 33, 0.74), rgba(12, 17, 33, 0.74)),
            radial-gradient(58% 66% at 28% 49%, rgba(12, 17, 33, 0.55) 31%, rgba(12, 17, 33, 0) 100%),
            url("../../images/mobile/mobile_punks3_landing_bg@2x.jpg");
        background-size: 109%;
        background-repeat: no-repeat;
        position: relative;
        padding-top: 23%;
    }

    .glitch-pixel {
        background: url("../../images/mobile/mobile_punks3_landing_pixels@2x.png");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 1135px;
        height: 473px;
        top: 0px;
    }

    .cores-section {
        background: linear-gradient(1.49deg, #0c1121 1.27%, rgba(12, 17, 33, 0) 55.32%),
            linear-gradient(180deg, #0c1121 0%, rgba(12, 17, 33, 0) 45.18%),
            linear-gradient(0deg, rgba(12, 17, 33, 0.74), rgba(12, 17, 33, 0.74)),
            url("../../images/mobile/mobile_punks3_mint_bg@2x.jpg");
        background-size: cover;
        position: relative;
        overflow: hidden;
        height: 980px;
        margin-top: -60px;
    }

    .cores-section .container {
        margin-top: -50px;
    }

    .cores-pixel {
        background: none;
    }

    .orb-section {
        position: relative;
        overflow: hidden;
        margin: 0px;
    }

    .orb-section .container {
        margin-top: 50px;
    }

    .orb-pixel {
        background: url("../../images/mobile/mobile_punks3_orb_pixels@2x.png");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -5px;
        width: 728px;
        height: 348px;
    }

    .glitch-img {
        width: 210px;
        height: 323px;
    }

    .orb-img {
        height: 233px;
    }

    .cores-img {
        width: 364px;
        height: 364px;
    }

    .info-title {
        font-size: 30px;
        line-height: 145%;
    }

    .orb-section .info-title {
        font-size: 18px;
        line-height: 175%;
    }

    .info-text {
        font-size: 14px;
    }

    .glitch-section .info-title,
    .orb-section .info-title {
        width: 100%;
    }

    .bottom-subtitle {
        font-size: 11px;
        margin-top: 25px;
    }

    .cores-section .bottom-subtitle {
        font-size: 11px;
        line-height: 215%;
    }

    .punks3-column img {
        width: 150px;
        height: 223px;
    }
}

@media (max-width: 600px) {
    .container {
        padding: 0 8%;
    }
}

@media (max-width: 425px) {
    .animation-gif {
        width: 300px;
    }
    .container {
        margin: 50px 10px;
    }

    .info-container {
        width: 100%;
    }

    .orb-section {
        margin-bottom: 50px;
    }

    .cores-section {
        height: 750px;
    }

    .cores-section .container {
        margin: 100px 0px;
    }

    .cores-img {
        width: 233px;
        height: 233px;
    }
    .cores-section .info-container {
        min-width: 20em;
    }
}

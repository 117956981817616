.pdf-section {
    margin: 150px 150px;
    display: flex;
    flex-direction: column;
}

.pdf-style {
    position: absolute;
    border: none;
    width: 100%;
    height: 55rem;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

@media (max-width: 1700px) {
    .pdf-section {
        margin: 150px 50px;
    }
}

@media (max-width: 1370px) {
    .pdf-style {
        height: 100%;
    }
}

@media (max-width: 1200px) {
    .pdf-section {
        margin: 150px 5px 150px 5px;
    }
}

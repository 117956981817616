.header {
  text-align: center;
  height: 10vh;
  margin-top: 10rem !important;
}

p {
  text-align: center;
  color: white;
  font-size: large;
  margin-bottom: 10rem;
}
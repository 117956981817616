.imageCardContainer {
  background-color: #18223E;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 258px;
  width: 193px;

  .cardImage {
    display: flex;
    justify-content: center;
    max-height: 50%;
    margin-bottom: 1.5rem;
  }

  .cardTitle {
    color:white;
  }
  .cardDescription {
    color: white;
    font-size: 9px;
    text-align: left;
  }
}

@media screen and (max-width: 760px) {
  .imageCardContainer {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 115px;
    
    .image {
      height: 6rem;
      margin-right: 10px;
    }

    .childrenContainer {
      width:100%;
      margin-top: -0.5rem;
      padding-left: 5rem !important
    }
  }

}
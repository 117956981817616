:root {
  /** Color Palette */
  --ocean-blue-primary: #101a32;
  --pink-primary: #d93978;
  --black-primary: #0c1121;
  --green-primary: #14c918;

  /* Font Colors */
  --text-light-primary: #ffffff;
  --text-dark-primary: #0c1121;

  /** Background Colors */
  --bg-dark-primary: #0c1121;
  --alt-bg-dark-primary: #000000;

  /** Dimensions and paddings */
  --navbar-height: 84px;
}

.progress-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
    gap: 20px;
}

.progress-bar {
    height: 5px;
    width: 100%;
    border-radius: 50px;
}

.progress-fill {
    height: 100%;
    border-radius: inherit;
    text-align: right;
}

.amount {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 175%;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: #fff;
}

@media screen and (max-width: 400px) {
    .progress-bar {
        height: 5px !important;
        min-width: 100%;
        border-radius: 50px;
    }
}